import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface GridState {
    refetchGrid: boolean;
}

const initialState: GridState = {
    refetchGrid: false
};

const refetchGridSlice = createSlice({
    name: 'refetchGrid',
    initialState,
    reducers: {
        setRefetchGrid: (state) => {
            state.refetchGrid = !state.refetchGrid;
        },
    },
});

export const {
    setRefetchGrid,
} = refetchGridSlice.actions;

export default refetchGridSlice.reducer;
