import React from 'react';
import './loadingOverlay.css'; // Подключение стилей

// @ts-ignore
const LoadingOverlay = ({progress}) => {
    return (
        <div className="overlay">
            <div className="progress-container">
                <h4>Загрузка файла...</h4>
                <div className="progress-bar">
                    <div
                        className="progress-bar-inner"
                        style={{width: `${progress}%`}}
                    ></div>
                </div>
                <p>{progress}%</p>
            </div>
        </div>
    );
};

export default LoadingOverlay;
