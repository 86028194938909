import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AnswerInterface} from "../../pages/responsible/page.remark";

interface AnswerType {
    isDecide: boolean;
    isReturned: boolean;
    returnComment: string | null;
    decideComment: string | null;
    selectedAnswerData: AnswerInterface | null;
}

const initialState: AnswerType = {
    isDecide: false,
    isReturned: false,
    returnComment: null,
    decideComment: null,
    selectedAnswerData: null
};

const answerSlice = createSlice({
    name: 'answer',
    initialState,
    reducers: {
        setSelectedAnswerData: (state, action: PayloadAction<any>) => {
            state.selectedAnswerData = action.payload;
        },
        setReturnComment: (state, action: PayloadAction<any>) => {
            state.returnComment = action.payload;
        },
        setDecideComment: (state, action: PayloadAction<any>) => {
            state.decideComment = action.payload;
        },
        setIsDecide: (state, action: PayloadAction<any>) => {
            state.isDecide = action.payload;
        },
        setIsReturned: (state, action: PayloadAction<any>) => {
            state.isReturned = action.payload;
        },
        clearAnswerState: (state) => {
            state.selectedAnswerData = null;
            state.returnComment = null;
            state.decideComment = null;
            state.isDecide = false;
            state.isReturned = false;
        },
    },
});

export const {
    setIsDecide,
    setIsReturned,
    setReturnComment,
    setDecideComment,
    setSelectedAnswerData,
    clearAnswerState

} = answerSlice.actions;

export default answerSlice.reducer;
