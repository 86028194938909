import React, {useState} from 'react';
import {Tabs} from '@consta/uikit/Tabs';
import {IconThumbUp} from '@consta/icons/IconThumbUp';
import {IconLightningBolt} from '@consta/icons/IconLightningBolt';

// Типы данных для вкладок
type TabsDataType = 'resolved' | 'not_resolved'

type Item = {
    name: string;
    image?: any;
    dataType: TabsDataType;
};

// Компоненты для иконок с заданным цветом
const RedLightningBoltIcon = () => <IconLightningBolt style={{width: '15px', height: 'auto', color: 'red'}}/>;
const GreenThumbUpIcon = () => <IconThumbUp style={{width: '15px', height: 'auto', color: 'green'}}/>;

const items: Item[] = [
    {
        name: "Не устранено",
        image: RedLightningBoltIcon,
        dataType: 'not_resolved'
    },
    {
        name: "Устранено",
        image: GreenThumbUpIcon,
        dataType: 'resolved'
    },
];

export type TabsJournalType = 'resolved' | 'not_resolved'

const getItemIcon = (item: any) => item.image;

interface TabsReportInterface {
    setTabsType: (tabsType: TabsJournalType) => void;
}

const TabsJournal = (props: TabsReportInterface) => {
    const [value, setValue] = useState<Item | null>(items[0]);

    const handleChangeTabs = (value: Item) => {
        setValue(value);
        props.setTabsType(value.dataType)
    };

    return (
        <div style={{maxWidth: '900px', margin: 'auto'}}>
            <Tabs
                value={value}
                onChange={(value) => handleChangeTabs(value)}
                items={items}
                getItemLabel={(item) => item.name}
                getItemLeftIcon={getItemIcon}
            />
        </div>
    );
};

export default TabsJournal;
