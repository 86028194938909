import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchNewChecklist, fetchSavedChecklist, fetchUpdateChecklist} from "../../utils/service/fetch.endpoint";
import MainInfo from "../../molecules/new/mainInfoMolecule.new";
import {useDispatch, useSelector} from "react-redux";
import {AnswerType, setValueMainInfo,} from "../../utils/features/checklistSlice";
import {BYPASS_TEMPLATE_ID} from "../../utils/service/config";
import {createNewChecklist} from "../../utils/helpers/createNewChecklist";
import {BuildSaveData} from "../../utils/helpers/buildSaveData";
import {getShouldSaveDraft} from "../../utils/helpers/getShouldSaveDraft";
import {mapQuestionToValueAnswer} from "../../utils/helpers/mapQuestionToValueAnswer";
import {buildMainInfoData} from "../../utils/helpers/buildMainInfoData";
import {
    selectUser,
    selectValueMainInfo,
    selectValueDate,
    selectViewMode,
    selectChecklistID,
    selectCurrentTemplate
} from '../../utils/features/selectors';
import {ComboboxType} from "../../core.component/mainInfo.constructor";
import {setAnswerData} from "../../utils/features/toBackendSlice";
import {Grid} from "@consta/uikit/Grid";
import RenderBypassList from "./view/render.bypass.checklist";
import {TemplateListDataType} from "../../types/new.type";

export interface PropsInterface {
    setChecklistData: any;
    mainMenuElements: ComboboxType[];
    setMainMenuElements: (elements: ComboboxType[]) => void;
    valueAnswer: AnswerType[]
    setValueAnswer: (elements: AnswerType[]) => void;
}

const ChecklistForm = (props: PropsInterface) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const valueMainInfo = useSelector(selectValueMainInfo);
    const valueDate = useSelector(selectValueDate);
    const viewMode = useSelector(selectViewMode);
    const checklistID = useSelector(selectChecklistID);
    const currentTemplate = useSelector(selectCurrentTemplate);
    const [questionData, setQuestionData] = useState<any>(null)


    // Запрос для получения данных нового чеклиста
    const {data: newChecklistData} = useQuery({
        queryKey: ['newChecklist'],
        queryFn: async () => {
            const response = await fetchNewChecklist({templateId: currentTemplate?.id});
            setQuestionData(response)
            createNewChecklist({
                checklistID: checklistID,
                currentTemplate: currentTemplate,
                username: user?.username ?? null,
                savedChecklistData: checklistData,
            })
            return response;
        },
        enabled: viewMode === 'new',
        refetchOnWindowFocus: false,
    });

    // Запрос для получения сохранённого чеклиста
    const {data: checklistData} = useQuery({
        queryKey: ['savedChecklist'],
        queryFn: async () => {
            const dictData = await fetchNewChecklist({templateId: BYPASS_TEMPLATE_ID});
            const response = await fetchSavedChecklist({checklistId: checklistID});
            dispatch(setValueMainInfo(response.mainInfo));
            setQuestionData(dictData)
            props.setChecklistData(response)
            return response;
        },
        enabled: viewMode !== 'new' && checklistID !== undefined
    });

    // Функция для обработки изменений и сохранения с задержкой
    const debounceChangeValueAnswer = useCallback((valueAnswer: [], valueMainInfo: any, valueDate: any) => {
        const mainInfo = buildMainInfoData(valueMainInfo);
        const saveData = BuildSaveData({
            mainInfo,
            valueAnswer,
            valueMainInfo,
            checklistID: checklistID,
            currentTemplate: currentTemplate,
            checklistData,
            valueDate
        });
        fetchUpdateChecklist({saveData, isDraft: true});
    }, [checklistID, currentTemplate, checklistData]);

    const handleDebouncedSave = useCallback((valueAnswer: any, valueMainInfo: any, valueDate: any) => {
        if (valueDate !== valueDate) {
            debounceChangeValueAnswer(valueAnswer, valueMainInfo, valueDate);
        }
    }, [debounceChangeValueAnswer, valueDate]);


    // Эффект для обработки перед закрытием окна
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const confirmationMessage = 'Изменения могут не сохраниться. Продолжить?';
            event.returnValue = confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Эффект для обработки сохранения черновика
    useEffect(() => {
        const shouldSaveDraft = getShouldSaveDraft(props, currentTemplate, props.valueAnswer, valueDate, valueMainInfo);
        if (shouldSaveDraft) {
            handleDebouncedSave(props.valueAnswer, valueMainInfo, valueDate);
        }
        dispatch(setAnswerData(props.valueAnswer))
    }, [props.valueAnswer, valueMainInfo, valueDate, currentTemplate, handleDebouncedSave]);

    // Эффект для построения данных основной информации
    useEffect(() => {
        buildMainInfoData({
            valueMainInfo,
            viewMode,
            currentTemplate,
            checklistData,
            dispatch,
            setMainMenuElements: props.setMainMenuElements
        });
    }, [currentTemplate, checklistData]);

    // Эффект для установки значений вопросов в зависимости от роли пользователя
    useEffect(() => {
        if (viewMode !== 'new' && checklistData?.question) {
            const {main_info, question} = checklistData;
            const mainInfoKeys = Object.keys(main_info).filter(key => key !== 'watch_date');
            const mainInfoValues = mainInfoKeys.map(key => main_info[key].answer);
            dispatch(setValueMainInfo(mainInfoValues));

            if (user && user?.userRole.toLowerCase() === 'инспектор') {
                props.setValueAnswer(question.map(mapQuestionToValueAnswer))
            } else if (user?.userRole.toLowerCase() === 'ответственный') {
                const negativeAnswers = question.filter((q: any) => q.answer === 'НЕТ');
                props.setValueAnswer(negativeAnswers.map(mapQuestionToValueAnswer))
            }
        }
    }, [questionData, checklistData, props.mainMenuElements, user?.userRole]);

    return (
        <>
            <MainInfo mainMenuElements={props.mainMenuElements} questionDataSource={questionData}/>

            <Grid className="gridSlave" cols={12} gap="2xs" style={{width: '90%'}}>
                {currentTemplate?.template_id === BYPASS_TEMPLATE_ID &&
                    (questionData?.question ? (
                        <RenderBypassList
                            questionData={questionData as TemplateListDataType}
                            checklistID={checklistID}
                            valueAnswer={props.valueAnswer}
                            setValueAnswer={props.setValueAnswer}
                        />
                    ) : (
                        <p>Loading</p>
                    ))}
            </Grid>
        </>
    );
};

export default ChecklistForm;
