export function chartDataProcess(reportData: any[], topLevel: string, selectedValue: string) {
    if (!reportData) {
        return 0
    }
    const recipientCounts: { [key: string]: number } = {};
    reportData.forEach((item: any) => {
        const recipient = item[topLevel];
        if (selectedValue === '*' || item.structural_subdivision === selectedValue) {
            recipientCounts[recipient] = (recipientCounts[recipient] || 0) + 1;
        }
    });

    return recipientCounts;
}