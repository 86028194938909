import {fetchCreateChecklist} from "../service/fetch.endpoint";
import {TemplateListDataType} from "../../types/new.type";

interface NewChecklistProps {
    checklistID: string;
    currentTemplate?: TemplateListDataType;
    username: string | null;
    savedChecklistData?: TemplateListDataType;
}

export const createNewChecklist = async (props: NewChecklistProps): Promise<void> => {
    const saveData = {
        create_type: 'new',
        checklist_id: props.checklistID,
        current_template: props.currentTemplate,
        checklist_data: {
            username: props.username,
            template_id: props.savedChecklistData?.id || props.currentTemplate?.id,
            is_draft: true,
        },
    };

    await fetchCreateChecklist({saveData, isDraft: true});
};
