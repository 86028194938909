import {ModuleRegistry} from "@ag-grid-community/core";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {RangeSelectionModule} from "@ag-grid-enterprise/range-selection";
import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";
import {RichSelectModule} from "@ag-grid-enterprise/rich-select";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {FiltersToolPanelModule} from "@ag-grid-enterprise/filter-tool-panel";
import {StatusBarModule} from "@ag-grid-enterprise/status-bar";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {LicenseManager} from "@ag-grid-enterprise/core";
import {MainInfoDictType} from "../../core.component/mainInfo.constructor";

// Константы
export const BYPASS_TEMPLATE_ID = "561d1a8e-b82a-47e7-8c56-5dee45fb5e46"
export const BYPASS_TEMPLATE_QUESTION_ID = '27fcc1f5-d630-42ab-91bc-e9b9cfc09d70'

export const config = {
    apiUrl: process.env.REACT_APP_API_URL,
};

// Функция для настройки лицензий и модулей ag-Grid
export const configureAgGrid = () => {
    ModuleRegistry.registerModules([
        ClientSideRowModelModule,
        RangeSelectionModule,
        RowGroupingModule,
        RichSelectModule,
        ColumnsToolPanelModule,
        FiltersToolPanelModule,
        StatusBarModule,
        MenuModule,
    ]);

    const license = process.env.REACT_APP_AGGRID;
    if (license) {
        LicenseManager.setLicenseKey(license);
    }
};

// todo HZHZHZH
export const MainInfoDict: MainInfoDictType = {
    struct: {
        id: 'c68cfa9e-e931-4452-8741-effac974a3cf',
        label: 'Структурное подразделение',
        model_name: 'StructuralSubdivision',
        result_name: 'struct',
        type: 'dict',
    },
    watch_place: {
        id: '2e6007ca-6fec-47b7-9c6d-44f0cc6e1cc3',
        label: 'Место осмотра',
        model_name: 'WatchPlace',
        result_name: 'watch_place',
        type: 'dict'
    },
    watch_org: {
        id: '6fb64aea-0b6e-4ea0-9de6-13db3dbdc5a5',
        label: 'Проверяемое предприятие',
        model_name: 'Org',
        result_name: 'watch_org',
        type: 'dict'
    },
    watch_ts_model: {
        id: '1063c6c9-7097-470b-b13f-e16f41ad5740',
        label: 'Марка, модель автомобиля',
        result_name: 'watch_ts_model',
        type: 'text'
    },
    watch_ts_gos_num: {
        id: '5f517387-186a-47ad-8eb1-38671a9014a4',
        label: 'Гос. рег. знак',
        result_name: 'watch_ts_gos_num',
        type: 'text'
    },
    watch_ts_year: {
        id: 'd6d81cef-e3ed-49e5-bc42-9eba4f55747e',
        label: 'Год выпуска',
        result_name: 'watch_ts_year',
        type: 'text'
    },
    watch_bush: {
        id: '888a491a-5774-4ecd-98de-518c11ffe457',
        label: 'КП',
        model_name: 'Bush',
        result_name: 'watch_bush',
        type: 'dict'
    }
}