import React, {useEffect, useRef, useState} from 'react';
import {ArcElement, Chart, Legend, PieController, Tooltip} from "chart.js";
import {Doughnut, getElementAtEvent} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {IconArrowUndone} from '@consta/icons/IconArrowUndone';
import {chartDataProcess} from "./helpers/chartDataProcess";
import {ChecklistTemplateType, fetchReportData} from "../../../utils/service/fetch.endpoint";
import {AnswerInterface} from "../../responsible/page.remark";

import {ReportDataInterface} from "../page.report.bypass";
import ChartLegend from "../../../molecules/report/doughnut.legend";
import {Text} from '@consta/uikit/Text';
import {SaveMethodType} from "../../../organisms/photoCollapse";

Chart.register(ArcElement, PieController, Legend, Tooltip, ChartDataLabels);

interface DoughnutChartInterface {
    chartData: AnswerInterface[];
    setChartData: (chartData: AnswerInterface[]) => void
    setFilterData: (filterData: AnswerInterface[]) => void
    dataType: SaveMethodType;
    onChartClick: any
    checklistTemplateType: ChecklistTemplateType
}

const DoughnutChart = (props: DoughnutChartInterface) => {
    const [reportData, setReportData] = useState<{}>(chartDataProcess(props.chartData, props.checklistTemplateType === 'bypass' ? 'structural_subdivision' : 'checklist_group', '*'));
    const [isBackEnabled, setIsBackEnabled] = useState<boolean>(false)
    const [centrLabel, setCentrLabel] = useState<number>(0)
    const chartRef: React.MutableRefObject<any> = useRef();


    let data = {
        labels: reportData && Object.keys(reportData),
        datasets: [
            {
                data: reportData && Object.values(reportData),
                backgroundColor: ["#20a8d8", "#fa6510", "#9c5fad", "#5a9428", "#70a1d8", "#ba6584"],
                hoverBackgroundColor: ["#ff07c5"],
                showInLegend: true,

            }
        ]
    };

    const options: any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "right",
                labels: {
                    font: {
                        size: 8 // Установите желаемый размер шрифта
                    }
                }
            },
            // tooltip: {
            //     callbacks: {
            //         label: function (tooltipItem: any) {
            //             const label = tooltipItem.label;
            //             const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
            //             return `${label}: ${value}`;
            //         }
            //     }
            // },
            datalabels: {
                display: true, // Отображать надписи на диаграмме
                font: {
                    size: 18 // Установите желаемый размер шрифта
                },
                formatter: (value: number, context: any) => {
                    // Форматирование надписей
                    // return `${context.chart.data.labels[context.dataIndex]} (${value})`;
                    return `${value}`;
                }
            },
            title: {
                display: false,
                text: props.dataType === 'new' ? 'Нарушения' : 'Устраненные'
            }
        }
    };


    const onClick = async (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        const activeElements = getElementAtEvent(chartRef.current, event);
        if (activeElements.length === 0) {
            return
        }

        const doughnutData = chartRef.current.data;
        const index = activeElements[0].index
        const label = doughnutData.labels[index];
        const value = doughnutData.datasets[0].data[index];

        if (props.checklistTemplateType === 'karkas') {
            const tempData = props.chartData.filter(items => items.checklist_group === label)
            props.onChartClick(tempData)
            setCentrLabel(tempData.length)
            return;
        }

        // если уже выбран
        if (isBackEnabled) {
            const tempData = props.chartData.filter(items => items.recipient === label)
            props.onChartClick(tempData)
            setCentrLabel(tempData.length)
            return;
        }

        const recipientCounts = chartDataProcess(props.chartData, 'recipient', label);
        setCentrLabel(props.chartData.filter(items => items.structural_subdivision === label).length)
        setReportData(recipientCounts)
        props.onChartClick(props.chartData.filter(items => items.structural_subdivision === label))

        setIsBackEnabled(true)

        // Обновить диаграмму
        // chartRef.current.update();
    }

    const handlerIconBackClick = async () => {
        const rootData: ReportDataInterface = await fetchReportData({checklistTemplate: props.checklistTemplateType})

        const recipientCounts = chartDataProcess(rootData[props.dataType], 'structural_subdivision', '*')
        setCentrLabel(rootData[props.dataType].length)
        setReportData(recipientCounts)
        props.onChartClick(rootData[props.dataType])
        setIsBackEnabled(false)
    }

    useEffect(() => {
        setReportData(chartDataProcess(props.chartData,
            props.checklistTemplateType === 'bypass' ? 'structural_subdivision' : 'checklist_group',
            '*'));
        props.setFilterData(props.chartData)
        setCentrLabel(props.chartData.length)
    }, [props.chartData]);


    useEffect(() => {
        setIsBackEnabled(false)
    }, [props.dataType]);

    return (
        <div className="doughnut-chart-container">
            <div className="doughnut-chart">
                <Doughnut ref={chartRef} data={data} options={options} onClick={onClick}
                          style={{
                              maxHeight: '20vh',
                              maxWidth: `${(20 / 100) * window.innerHeight}px`,
                              padding: '15px'
                          }}/>
                {centrLabel > 0 &&
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <Text size='3xl'>{centrLabel}</Text>
                    </div>}
                <div style={{position: 'absolute', bottom: '2%', right: '2%'}}>
                    {isBackEnabled && <IconArrowUndone onClick={handlerIconBackClick}/>}
                </div>
            </div>
            <ChartLegend labels={data.labels} colors={data.datasets[0].backgroundColor}/>
        </div>
    )

}
export default DoughnutChart;