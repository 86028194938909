import {createSlice, PayloadAction} from "@reduxjs/toolkit";

// Тип для элемента списка
export interface NotificationItem {
    org_name: any;
    photo_file: any;
    watch_place: string;
    date: string;
    orgName: string;
    fio: string;
    answer: string;

}

// Тип для состояния списка
interface NotificationState {
    items: NotificationItem[];
}

// Начальное состояние
const initialState: NotificationState = {
    items: [],
};

// Создаем слайс для списка
const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<any>) => {
            state.items = action.payload;
        },
        addItem: (state, action: PayloadAction<NotificationItem>) => {
            state.items.push(action.payload);
        },
        // Удалить элемент из списка по индексу
        removeItem: (state, action: PayloadAction<number>) => {
            state.items.splice(action.payload, 1);
        },
        // Обновить элемент в списке по индексу
        updateItem: (state, action: PayloadAction<{ index: number; item: NotificationItem }>) => {
            state.items[action.payload.index] = action.payload.item;
        },
        // Очистить список
        clearList: (state) => {
            state.items = [];
        },
    },
});

// Экспортируем экшены
export const {addItem, removeItem, updateItem, clearList, setNotifications} = notificationSlice.actions;

// Экспортируем редьюсер для подключения к store
export default notificationSlice.reducer;
