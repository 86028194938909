import {AnswerType} from "../features/checklistSlice";

export const mapQuestionToValueAnswer = (q: AnswerType) => (
    {
        id: q.id,
        answer: q.answer,
        comment: q.comment,
        number: q.number,
        recipient: q.recipient,
        watch_object: q.watch_object,
        is_karkas: q.is_karkas,
        photo: q.photo,
        thumbnail: q.thumbnail,
        assigned_id: q.assigned_id,
        assigned_name: q.assigned_name,
        is_decided: q.is_decided,
        decided_date: q.decided_date,
        is_returned: q.is_returned,
        is_confirmed: q.is_confirmed
    }
);
