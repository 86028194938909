import React from "react";
import { ContextMenu } from '@consta/uikit/ContextMenu';
import {ItemLogout} from "./header.organism";


const LogoutContextMenuComponent = ({ items, isOpen, anchorRef, onClickOutside, getItemOnClick, renderLeftSide }: {
  items: ItemLogout[],
  isOpen: boolean,
  anchorRef: any,
  onClickOutside: () => void,
  getItemOnClick: (item: ItemLogout) => () => void,
  renderLeftSide: (item: ItemLogout) => React.ReactNode
}) => {
  return (
    <ContextMenu
      className='header_context_menu'
      isOpen={isOpen}
      items={items}
      onClickOutside={onClickOutside}
      anchorRef={anchorRef}
      getItemOnClick={getItemOnClick}
      getItemLeftSide={renderLeftSide}
    />
  );
};

export default LogoutContextMenuComponent;
