import React, {useState} from 'react';
import AgGridTable from "../organisms/grid/gridJournal";
import TabsJournal, {TabsJournalType} from "../organisms/journal/tabs.Organism.journal";
import {useDispatch, useSelector} from "react-redux";
import {setChecklistID, setSelectedTemplate, setViewMode} from "../utils/features/checklistSlice";
import {setIsChecklistSidebarOpen} from "../utils/features/sidebarsOpenSlice";
import ChecklistSidebar from "../organisms/sidebars/checklistSidebar";
import {ViewModeType} from "../types/new.type";
import {selectUrlPath} from "../utils/features/selectors";

export interface JournalTemplateProps {
    fetchType?: ViewModeType
}

const JournalPage: React.FC<JournalTemplateProps> = ({fetchType}) => {
    const dispatch = useDispatch()
    const [tabsType, setTabsType] = useState<TabsJournalType>('not_resolved');

    const urlPath = useSelector(selectUrlPath);
    const handleOpenSidebar = (id: string, template: any,) => {
        dispatch(setChecklistID(id));
        dispatch(setSelectedTemplate(template));
        dispatch(setIsChecklistSidebarOpen(true));
        dispatch(setViewMode(fetchType === 'draft' ? 'draft' : 'view'))
    };


    return (
        <>
            <ChecklistSidebar isJournal={true}/>

            {urlPath === '/journal' && <TabsJournal setTabsType={setTabsType}/>}

            <AgGridTable onOpenSidebar={handleOpenSidebar} fetchType={fetchType ?? tabsType}/>
        </>
    );
};

export default JournalPage;
