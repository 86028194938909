import {createSlice, PayloadAction} from '@reduxjs/toolkit';


interface SidebarsOpenState {
    isChecklistSidebarOpen: boolean;
    isAnswerSidebarOpen: boolean;
    isNullCountModalOpen: boolean;
}

const initialState: SidebarsOpenState = {
    isChecklistSidebarOpen: false,
    isAnswerSidebarOpen: false,
    isNullCountModalOpen: false,
};

const sidebarsOpenSlice = createSlice({
    name: 'sidebarsOpen',
    initialState,
    reducers: {

        setIsChecklistSidebarOpen(state, action: PayloadAction<any>) {
            state.isChecklistSidebarOpen = action.payload;
        },
        setIsAnswerSidebarOpen: (state) => {
            state.isAnswerSidebarOpen = !state.isAnswerSidebarOpen
        },
        setNullModalOpen(state) {
            state.isNullCountModalOpen = !state.isNullCountModalOpen;
        },
    },
});

export const {setIsChecklistSidebarOpen, setIsAnswerSidebarOpen, setNullModalOpen,} = sidebarsOpenSlice.actions;

export default sidebarsOpenSlice.reducer;
