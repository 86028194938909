import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import Button from "../../atoms/new/buttonAtom.new";


interface ButtonGroupProps {
    onCancel: () => void;
    onSave: () => void;
    onDraft: () => void;
    showSaveButton: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({onCancel, onSave, onDraft, showSaveButton}) => {
    return (
        <Grid cols={showSaveButton ? 3 : 1} gap={'2xs'}>
            <GridItem col={1}>
                <Button label="Отменить" onClick={onCancel} view="ghost" width="full"/>
            </GridItem>

            {showSaveButton && (
                <GridItem col={1}>
                    <Button className='button-draft' label="В черновик" onClick={onDraft} view="ghost" width="full"/>
                </GridItem>
            )}
            {showSaveButton && (
                <GridItem col={1}>
                    <Button className='button-save' label="Сохранить" onClick={onSave} view="ghost" width="full"/>
                </GridItem>
            )}
        </Grid>
    );
};

export default ButtonGroup;
