import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {Grid, GridItem} from "@consta/uikit/Grid";
import GridReport from "../../organisms/grid/gridReport";
import {fetchReportData} from "../../utils/service/fetch.endpoint";
import {AnswerInterface} from "../responsible/page.remark";
import TabsReport from "./component/tabs.report";
import {SaveMethodType} from "../../organisms/photoCollapse";
import {useSelector} from "react-redux";
import {selectUser} from "../../utils/features/selectors";

export interface ReportDataInterface {
    new: AnswerInterface[]
    decided: AnswerInterface[]
    returned: AnswerInterface[]
}

interface PageReportBypass {
    isRefetchData?: boolean
    setIsRefetchData?: (isRefetchData: boolean) => void
}


const PageReportBypass = (props: PageReportBypass) => {
    const [filterData, setFilterData] = useState<ReportDataInterface | null>(null)
    const [chartData, setChartData] = useState<ReportDataInterface | null>(null)
    const [dataType, setDataType] = useState<SaveMethodType>('new');

    const user = useSelector(selectUser);

    const {data: reportData, refetch} = useQuery({
        queryKey: ['reportData'],
        queryFn: async () => {
            const fetchReportDataResult = await fetchReportData({checklistTemplate: 'bypass'})
            // setFilterReportData(fetchReportDataResult)
            setChartData(fetchReportDataResult)
            return fetchReportDataResult
        },
        // enabled: viewMode !== 'new'
    });

    const handleRefetch = () => {
        refetch();
    };


    useEffect(() => {
        handleRefetch()
    }, [dataType]);

    useEffect(() => {
        user?.isContract && reportData &&
        setFilterData(reportData)
    }, [reportData]);

    return (
        <React.Fragment>
            {reportData &&
                <Grid className={!user?.isContract ? "gridSlave" : 'grid_none'} cols={2}
                      style={{width: !user?.isContract ? '90%' : '100%'}} colGap="xs">
                    {!user?.isContract && chartData &&
                        <React.Fragment>
                            <GridItem col={2}>
                                <TabsReport dataType={dataType}
                                            setDataType={setDataType}
                                            chartData={chartData}
                                            setChartData={setChartData}
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                            checklistTemplateType={'bypass'}
                                />
                            </GridItem>

                            {/*<GridItem col={2} style={{flexGrow: 0, marginTop: '10px'}}>*/}
                            {/*    <Card>*/}
                            {/*        <PointChart filterData={filterData}*/}
                            {/*                    setFilterData={setFilterData}*/}
                            {/*                    dataType={dataType}/>*/}
                            {/*    </Card>*/}
                            {/*</GridItem>*/}
                        </React.Fragment>
                    }

                    <GridItem className='grid-table-violante' col={2} style={{marginTop: '10px', flexGrow: 1}}>
                        {filterData !== null &&
                            <GridReport filterData={filterData}
                                        viewType='report'
                                        checklistTemplateType={'bypass'}/>}
                    </GridItem>
                </Grid>
            }


        </React.Fragment>
    );
}
export default PageReportBypass;