import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TemplateListDataType, ViewModeType} from '../../types/new.type';

export interface AnswerType {
    id: string;
    answer: string;
    answer_id: string;
    comment: string | null;
    number: string;
    recipient: {
        id: number;
        name: string;
    };
    watch_object: {
        id: number;
        name: string;
    };
    photo: any[];
    thumbnail: any[];
    assigned_id: string | null;
    assigned_name?: string;
    is_karkas?: boolean;
    is_decided?: boolean;
    decided_date?: string | null;
    is_returned?: boolean;
    is_confirmed?: boolean;
}


interface ChecklistState {
    selectedTemplate?: TemplateListDataType;
    checklistID: string;
    saveSuccess: boolean;
    valueDate: string | null;
    questionData: any | null;

    valueMainInfo: any[];
    valueAnswer: AnswerType[];
    viewMode: ViewModeType;
}

const initialState: ChecklistState = {
    selectedTemplate: undefined,
    checklistID: '', // Можно генерировать uuid в компоненте или здесь
    saveSuccess: false,
    valueDate: null,
    questionData: null,

    valueMainInfo: [],
    valueAnswer: [],
    viewMode: null,

};

const checklistSlice = createSlice({
    name: 'checklist',
    initialState,
    reducers: {
        setSelectedTemplate: (state, action: PayloadAction<any>) => {
            state.selectedTemplate = action.payload;
        },
        setChecklistID: (state, action: PayloadAction<string>) => {
            state.checklistID = action.payload;
        },
        setValueDate(state, action: PayloadAction<string | null>) {
            state.valueDate = action.payload;
        },
        setValueMainInfo(state, action: PayloadAction<any[]>) {
            state.valueMainInfo = action.payload;
        },
        setValueAnswer(state, action: PayloadAction<any[]>) {
            state.valueAnswer = action.payload;
        },
        setViewMode(state, action: PayloadAction<ViewModeType>) {
            state.viewMode = action.payload;
        },

        // Добавляем экшены для изменения статусов ответов
        updateAnswerStatus: (state, action: PayloadAction<{
            index: number,
            is_confirmed?: boolean,
            is_returned?: boolean,
            is_decided?: boolean,
        }>) => {
            const {index, is_confirmed, is_returned, is_decided} = action.payload;

            if (state.valueAnswer && index >= 0 && index < state.valueAnswer.length) {
                const answer = state.valueAnswer[index];
                if (is_confirmed !== undefined) answer.is_confirmed = is_confirmed;
                if (is_returned !== undefined) answer.is_returned = is_returned;
                if (is_decided !== undefined) answer.is_decided = is_decided;
            }
        },

        clearChecklistState: (state) => {
            state.selectedTemplate = undefined;
            state.checklistID = '';
            state.saveSuccess = false;
            state.valueDate = null;
            state.questionData = null;
            state.valueMainInfo = [];
            state.valueAnswer = [];
            state.viewMode = null;
        },

    },
});

export const {
    setSelectedTemplate,
    setChecklistID,
    setValueDate,
    setValueMainInfo,
    setValueAnswer,
    setViewMode,
    clearChecklistState,
    updateAnswerStatus

} = checklistSlice.actions;

export default checklistSlice.reducer;
