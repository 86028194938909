import React from 'react';
import {Card} from '@consta/uikit/Card';
import {Grid, GridItem} from '@consta/uikit/Grid';
import {useDispatch} from 'react-redux';
import {
    setChecklistID,
    setSelectedTemplate,
    setViewMode
} from '../../utils/features/checklistSlice';
import {BYPASS_TEMPLATE_ID} from "../../utils/service/config";
import {TemplateListDataType} from "../../types/new.type";
import {setIsChecklistSidebarOpen} from "../../utils/features/sidebarsOpenSlice";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';

interface ChecklistSelectorProps {
    templateList: TemplateListDataType[]; // Укажите точный тип, если у вас есть информация о структуре данных
}

const ChecklistSelector: React.FC<ChecklistSelectorProps> = ({templateList}) => {
    const dispatch = useDispatch();

    const handleBypass = () => {
        dispatch(setViewMode('new'));
        dispatch(setChecklistID(uuidv4()));
        const bypassTemplate = templateList.find((item: TemplateListDataType) => item.id === BYPASS_TEMPLATE_ID);

        if (bypassTemplate) {
            const updatedTemplate = {
                ...bypassTemplate, // Копируем все свойства
                template_id: BYPASS_TEMPLATE_ID // Добавляем/изменяем нужное свойство
            };
            dispatch(setSelectedTemplate(updatedTemplate));
            dispatch(setIsChecklistSidebarOpen(true));
        }

    };

    return (
        <React.Fragment>
            <Grid className="gridSlave" cols={1}>
                <GridItem col={1} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    <Card className="selector-card-container">
                        <div className="selector-bypass-container" onClick={handleBypass}>
                            <div className="selector-bypass-right-block">
                                <img src="/new_bypass.jpg" alt="Обходной чеклист"/>
                                <div className="selector-bypass-button">
                                    ОБХОД
                                </div>
                            </div>
                        </div>
                    </Card>
                </GridItem>
            </Grid>
        </React.Fragment>
    );
};

export default ChecklistSelector;
