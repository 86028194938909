import React, {useEffect, useState} from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Loader} from '@consta/uikit/Loader';
import AnswerCountCard from "./component/answer.card";
import GridReport from "../../organisms/grid/gridReport";
import PageReportBypass from "../report/page.report.bypass";
import {Collapse} from '@consta/uikit/Collapse';
import {Badge} from "@consta/uikit/Badge";
import {useReportData, useResponsibleData} from "../../hooks/useViolantePageData";
import {useSelector} from "react-redux";
import {selectUser} from "../../utils/features/selectors";

export type viewTypeType = 'responsible_cabinet' | 'report';

export interface AnswerCommentInterface {
    id: string;
    answer: string;
    comment_type: 'decided' | 'returned';
    comment_text: string;
    user: string;
    user_name: string;
    created_at: string;
    updated_at: string;
}

export interface AnswerInterface {
    answer_id: string;
    checklist_id: string;
    author: string;
    date: string;
    checklist: string;
    watch_place: string;
    watch_object: string;
    structural_subdivision: string;
    recipient: string;
    is_karkas: boolean;
    answer: string;
    number: string;
    photo: string[];
    thumbnails: string[];
    is_decided: boolean;
    is_returned: boolean;
    is_confirmed: boolean;
    decided_photo: string[];
    decided_thumbnails: string[];
    checklist_group: string;
    checklist_name: string;
    updated_at: string;
    answer_comment: AnswerCommentInterface[];

    // deprecated
    decided_answer: string;
    decided_date: Date;
    returned_answer: string;

}

const PageRemark = () => {
    const [filterReportData, setFilterReportData] = useState<AnswerInterface[] | undefined>(undefined);
    const [isOpenRegistry, setOpenRegistry] = useState<boolean>(false);
    const [isRefetchData, setIsRefetchData] = useState<boolean>(false);
    const user = useSelector(selectUser);

    const {
        data: fetchData,
        refetch: refetchData,
        isLoading: isResponsibleLoading
    } = useResponsibleData(!!user?.isContract);

    const {
        data: reportData,
        refetch: refetchReportData,
        isLoading: isReportLoading
    } = useReportData(!!user?.isContract);

    useEffect(() => {
        refetchData();
        setFilterReportData(undefined);
    }, [isRefetchData]);


    return (
        <React.Fragment>
            <Grid className='gridSlave' cols={2} colGap="xs" rowGap="xs">
                <GridItem col={2}>
                    {reportData && reportData.length > 0 && (
                        <Collapse
                            label={<Badge status="error" label="ЕСТЬ НЕ РАСПРЕДЕЛЕННЫЕ НАРУШЕНИЯ" size="s"/>}
                            isOpen={isOpenRegistry}
                            onClick={() => setOpenRegistry(!isOpenRegistry)}
                        >
                            <PageReportBypass isRefetchData={isRefetchData} setIsRefetchData={setIsRefetchData}/>
                        </Collapse>
                    )}
                </GridItem>

                <GridItem col={1}>
                    {isResponsibleLoading ? <Loader size="s"/> : fetchData && (
                        <AnswerCountCard
                            dataAnswer={fetchData.bypass_data.assigned}
                            otherAnswer={fetchData.bypass_data.returned}
                            cardType={"assigned"}
                            setFilterReportData={setFilterReportData}
                        />
                    )}
                </GridItem>

                <GridItem col={1}>
                    {isResponsibleLoading ? <Loader size="s"/> : fetchData && (
                        <AnswerCountCard
                            dataAnswer={fetchData.bypass_data.decided}
                            otherAnswer={fetchData.bypass_data.waiting}
                            cardType={"decided"}
                            setFilterReportData={setFilterReportData}
                        />
                    )}
                </GridItem>

                {filterReportData && (
                    <GridItem className='grid-table-violante' col={2}>
                        <GridReport
                            filterData={filterReportData}
                            viewType='responsible_cabinet'
                            checklistTemplateType={'bypass'}
                            setRefetchData={setIsRefetchData}
                        />
                    </GridItem>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default PageRemark;
