import axios from "axios";
import {config} from "./config";


export const axiosInstance = axios.create({
    baseURL: config.apiUrl,
    timeout: 30000,
});

// Добавляем заголовок с токеном в каждый запрос
axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // Проверка, является ли data FormData
    if (config.data instanceof FormData) {
        // Удаляем Content-Type, если это FormData
        delete config.headers['Content-Type'];
    } else if (config.data && typeof config.data === 'object' && !Array.isArray(config.data)) {
        // Устанавливаем Content-Type для обычных объектов
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    return config;
});