import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Text} from "@consta/uikit/Text";
import React from "react";
import {ViewModeType} from "../../types/new.type";

export const renderField = (
    fieldType: 'combobox' | 'textfield' | 'textarea',
    label: string,
    value: any,
    placeholder: string,
    status: 'alert' | 'success',
    index: number,
    changeHandler: (value: any, index: number, field: string) => void,
    items?: any[],
    field?: string,
    viewMode?: ViewModeType,
) => {
    if (viewMode !== 'view') {
        if (fieldType === 'combobox') {
            return (
                <Combobox
                    placeholder={placeholder}
                    items={items || []}
                    size="m"
                    status={status}
                    value={value?.name ? value : undefined}  // Передаем undefined если value не выбрано
                    onChange={(value) => changeHandler(value, index, field!)}
                    getItemKey={(item: any) => item.name}
                    getItemLabel={(item: any) => item.name}
                />
            );
        } else {
            return (
                <TextField
                    value={value}
                    status={status}
                    onChange={(value) => changeHandler(value, index, field!)}
                    placeholder={placeholder}
                    style={{width: '100%'}}
                    type={fieldType === 'textfield' ? 'text' : 'textarea'}
                    rows={fieldType === 'textfield' ? undefined : 3}
                />
            );
        }
    } else {
        return (
            <div style={{marginBottom: "10px"}}>
                <Text size="m" view="primary" weight="bold">{label}:</Text>
                <Text
                    size="m"
                    style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word'
                    }}>{typeof value === 'object' ? (value?.name || "—") : value}</Text>

            </div>
        );
    }
};

