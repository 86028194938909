import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import "../../styles/login.css"
import {useAuth} from "../../utils/wrapper/authContext";
import {loginUser} from "../../utils/service/login.service";
import LoginTemplate from "../../templates/login.template";

const LoginPage: React.FC = () => {
    const [loginError, setLoginError] = useState<string | null>(null);
    const {login} = useAuth();
    const navigate = useNavigate();

    const onSubmit = async (data: { username: string; password: string }) => {
        try {
            const {access, refresh} = await loginUser(data);
            login(access, refresh);
            navigate('/home');
        } catch (error) {
            setLoginError('Неправильное имя пользователя или пароль');
        }
    };

    useEffect(() => {
        document.title = 'Чеклист | Авторизация';
    }, []);

    return (
        <LoginTemplate
            onSubmit={onSubmit}
            loginError={loginError}
            errors={{}}
        />
    );
};

export default LoginPage;
