import React, {useRef, useState, useMemo} from "react";
import {Layout} from '@consta/header/Layout';
import {useNavigate} from "react-router-dom";
import {IconExit} from '@consta/icons/IconExit';
import {IconDinosaur} from '@consta/icons/IconDinosaur';
import {Menu} from '@consta/header/Menu';
import UserComponent from "./userComponent.atom.header";
import LogoutContextMenuComponent from "./logoutContextMenuCmp.molecule.header";
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../utils/service/reduxStore';
import '../../styles/header.css';
import "./style.css";
import {setUrlPath} from "../../utils/features/urlSlice";
import {selectUser} from "../../utils/features/selectors";


export type ItemLogout = {
    label: string;
    icon: any;
    event?: () => void;
};

const HeaderOrganism = (props: { menuItems: any }) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser);
    const [isOpenLogout, setIsOpenLogout] = useState<boolean>(false);
    const ref = useRef(null);
    const nav = useNavigate();

    const itemsLogout: ItemLogout[] = useMemo(() => [
        {
            label: 'Выйти',
            icon: IconExit,
            event: () => handleLogout(),
        },
        ...(!user?.isContract ? [{
            label: 'Dashboard обход',
            icon: IconDinosaur,
            event: () => handleMenuItemClick('/total_info_bypass'),
        }] : [])
    ], [user]);


    const handleMenuItemClick = (path: string) => {
        dispatch(setUrlPath(path))
        setIsOpenLogout(false);
    };

    const handleLogout = () => {
        localStorage.clear();
        nav('/');
        window.location.reload();
        setIsOpenLogout(false);
    };

    const getItemOnClickLogout = (item: ItemLogout) => item.event || (() => alert(item.label));

    const renderLeftSide = (item: ItemLogout): React.ReactNode => {
        const Icon = item.icon;
        return <Icon size="s"/>;
    };


    // @ts-ignore
    return (<>            <Layout
            rowTop={{
                left: undefined,
                center: <Menu items={props.menuItems}/>,
                right: <UserComponent
                    name={`${user?.last_name} ${user && user.first_name}`}
                    info={user?.user_company ?? ''}
                    onClick={() => setIsOpenLogout(!isOpenLogout)}
                    refProp={ref}/>
            }}/>
            <LogoutContextMenuComponent
                items={itemsLogout}
                isOpen={isOpenLogout}
                anchorRef={ref}
                onClickOutside={() => setIsOpenLogout(false)}
                getItemOnClick={getItemOnClickLogout}
                renderLeftSide={renderLeftSide}
            />
        </>
    );
};

export default HeaderOrganism;
