import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import {ViewModeType} from "../../types/new.type";

interface ButtonGroupProps {
    checklistID: string;
    viewMode: ViewModeType;
    setSavePressed: (pressed: boolean) => void;
    handleCancelButton: (checklistID: string) => void;
}

const ButtonGroupJournal: React.FC<ButtonGroupProps> = ({viewMode, checklistID, setSavePressed, handleCancelButton}) => {
    return (
        <Grid cols={viewMode !== 'view' ? 2 : 1}>
            <GridItem col={1}>
                <Button
                    size="m"
                    view="ghost"
                    label="Закрыть"
                    width="full"
                    onClick={() => handleCancelButton(checklistID)}
                />
            </GridItem>
            {viewMode !== 'view' &&
                <GridItem col={1}>
                    <Button
                        size="m"
                        view="ghost"
                        label="Сохранить"
                        width="full"
                        onClick={() => setSavePressed(true)}
                    />
                </GridItem>}
        </Grid>
    );
};

export default ButtonGroupJournal;
