import React from "react";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";
import {TextField} from "@consta/uikit/TextField";
import {selectReturnComment} from "../../utils/features/selectors";
import {useDispatch, useSelector} from "react-redux";
import {setReturnComment} from "../../utils/features/answerSlice";


const RejectRemarkCard: React.FC = () => {
    const dispatch = useDispatch()
    const returnComment = useSelector(selectReturnComment)

    return (
        <Card style={{margin: '15px', padding: '5px'}}>
            <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                Причина отклонения:
            </Text>
            <TextField
                value={returnComment}
                onChange={(value) => dispatch(setReturnComment(value))}
                style={{width: '100%', marginBottom: "15px"}}
                type="textarea"
                placeholder="Причина"
                labelPosition="top"
                rows={5}
            />
        </Card>
    );
};

export default RejectRemarkCard;
