import {MainInfoDict} from "../../../../core.component/mainInfo.constructor";
import {AnswerType} from "../../../../utils/features/checklistSlice";

interface PropsInterface {
    mainInfo: any;
    valueAnswer: any;
    valueMainInfo: any;
    checklistID: string;
    currentTemplate: any;
    savedChecklistData?: any;
    valueDate: Date | null;
    username?: string;
}

export const BuildSaveData = (props: PropsInterface) => {
    //  формирование словаря для сохранения в БД
    let mainInfoAnswer = {};
    let osmotrPlaceID: {} | null = null;

    // Создадим объект для соответствия имен
    const valueMainInfoDict: { [key: string]: any } = {};

    // Пробегаем по mainInfo, чтобы присвоить значения полям watch_place и struct
    props.mainInfo &&
    props.mainInfo.forEach((el: any, index: number) => {
        // Инициализация значений для watch_place и struct
        let value = props.valueMainInfo[index];

        // Если это watch_place, сохраняем отдельно, но присваиваем значение struct
        if (el.result_name === 'watch_place') {
            osmotrPlaceID = props.valueMainInfo[index];
            value = props.valueMainInfo.find((_: any, i: any) => props.mainInfo[i]?.result_name === 'watch_place');
        }

        // Если это struct, меняем его значение на значение watch_place
        if (el.result_name === 'struct') {
            value = props.valueMainInfo.find((_: any, i: any) => props.mainInfo[i]?.result_name === 'struct');
        }

        // Заполним словарь, чтобы привязать значения к именам
        valueMainInfoDict[el.result_name] = value;
    });

    // Теперь заполняем mainInfoAnswer, связывая по ключам
    props.mainInfo.forEach((el: any) => {
        mainInfoAnswer = {
            ...mainInfoAnswer,
            [el.result_name]: {
                id: MainInfoDict[el.result_name].id,
                answer: valueMainInfoDict[el.result_name],
            },
        };
    });

    // Создаем объект для сохранения
    const saveData = {
        create_type: "complete",
        checklist_id: props.checklistID,
        current_template: props.currentTemplate,
        checklist_data: {
            username: props.username,
            template_id: props.savedChecklistData?.id || props.currentTemplate?.template_id,
            watch_date: props.valueDate,
            is_draft: false,
            is_trash: false,
            watch_place: osmotrPlaceID, // сохраняем watch_place отдельно
        },
        main_info: mainInfoAnswer,
        answer: props.valueAnswer.map(({
                                           photo,
                                           thumbnail,
                                           assigned_id,
                                           assigned_name,
                                           is_decided,
                                           decided_date,
                                           is_returned,
                                           ...rest
                                       }: AnswerType) => rest)
    };

    return saveData;
};
