import {SnackBar, SnackBarItemStatus} from "@consta/uikit/SnackBar";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../utils/service/reduxStore";
import {hideSnackBar} from "../utils/features/snackBarSlice";

export type ItemType = {
    key: number;
    message: string;
    status: SnackBarItemStatus;
    progressMode?: "line" | "timer";
};

export const getItemShowProgress = (item: ItemType) => item.progressMode;

const SnackBarComponent = () => {
    const dispatch = useDispatch()
    const {showSnackBar: show, messageText, status, progressMode} = useSelector(
        (state: RootState) => state.snackBar
    );

    const [visible, setVisible] = useState(show);

    useEffect(() => {
        setVisible(show);
    }, [show]);

    const handleItemClose = () => {
        setVisible(false);
        dispatch(hideSnackBar())
    };

    return (
        <React.Fragment>
            {visible && (
                <SnackBar
                    style={{position: "absolute", bottom: 0, zIndex: 1000}}
                    items={[
                        {
                            key: Date.now(),
                            message: messageText,
                            status: status,
                            progressMode: progressMode,
                        },
                    ]}
                    onItemClose={handleItemClose}
                    getItemShowProgress={getItemShowProgress}
                    getItemAutoClose={() => 2}
                />
            )}
        </React.Fragment>
    );
};

export default SnackBarComponent;
