import React from "react";
import {GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {TextField} from "@consta/uikit/TextField";
import PhotoCollapse from "../../organisms/photoCollapse";
import {Card} from "@consta/uikit/Card";
import {selectDecideComment} from "../../utils/features/selectors";
import {useDispatch, useSelector} from "react-redux";
import {setDecideComment} from "../../utils/features/answerSlice";
import {AnswerInterface} from "../../pages/responsible/page.remark";

interface AnswerFormProps {
    selectedAnswerData: AnswerInterface
}

const AnswerForm: React.FC<AnswerFormProps> = (props) => {
    const dispatch = useDispatch();
    const decideComment = useSelector(selectDecideComment)

    return (
        <Card style={{margin: '15px', padding: '5px'}}>
            <GridItem col={3}>
                <Text className="answer_detail_h1" size="3xl" view="brand" weight="bold">
                    Отчет об устранении
                </Text>
            </GridItem>

            <GridItem col={3}>
                <TextField
                    value={decideComment}
                    onChange={(value) => dispatch(setDecideComment(value))}
                    status={!decideComment ? 'alert' : undefined}
                    style={{width: "100%", marginBottom: "15px"}}
                    type="textarea"
                    placeholder="Выполненные мероприятия"
                    labelPosition="top"
                    rows={5}
                />
            </GridItem>

            <GridItem col={3}>
                <PhotoCollapse
                    index={0}
                    answerID={props.selectedAnswerData?.answer_id ?? ''}
                    photo={props.selectedAnswerData?.decided_photo}
                    thumbnail={props.selectedAnswerData?.decided_thumbnails}
                    saveMethod="decided"
                    canDelete={true}
                />
            </GridItem>
        </Card>
    );
};

export default AnswerForm;
