import React from 'react';
import {Button as ConstaButton} from "@consta/uikit/Button";

interface ButtonProps {
    label: string;
    onClick: () => void;
    size?: 'm' | 's';
    view?: 'primary' | 'ghost';
    width?: 'full' | 'default';
    style?: any;
    className?: string
}

const ButtonAtom: React.FC<ButtonProps> = ({
                                               label,
                                               style,
                                               onClick,
                                               size = 'm',
                                               view = 'primary',
                                               width = 'default',
                                               className = ''
                                           }) => {
    return (
        <ConstaButton
            className={className}
            style={style}
            size={size}
            view={view}
            label={label}
            width={width}
            onClick={onClick}
        />
    );
};

export default ButtonAtom;
