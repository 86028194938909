// ###################################################################
// AUTHENTICATION
// ###################################################################
export const API_CHECK_AUTH_URL = `/api/v1/user/`
export const API_TOKEN_REFRESH = `/api/token/refresh/`
export const API_TOKEN_OBTAIN = `/api/token`

// ###################################################################
// DICT
// ###################################################################
export const API_DICT_TEMPLATE_GET = `/api/v1/dict/template/get`
export const API_DICT_QUESTION_GET = `/api/v1/dict/question/get`

// ###################################################################
// REGISTRY
// ###################################################################
export const API_REGISTRY_CHECKLIST = `/api/v1/registry/checklist/`
export const API_REGISTRY_ANSWER = `/api/v1/registry/answer/`
export const API_REGISTRY_ANSWER_ASSIGN = `/api/v1/registry/answer/assign/`
export const API_REGISTRY_SAVE_DRAFT_ANSWER = `/api/v1/registry/answer/save_draft/`
export const API_REGISTRY_UPLOAD_PHOTO = `/api/v1/registry/photo/upload/`
export const API_REGISTRY_REPORT_DATA = `/api/v1/registry/answer/report_data`
export const API_REGISTRY_RESPONSIBLE_DATA = `/api/v1/registry/answer/responsible`
export const API_REGISTRY_PHOTO = `/api/v1/registry/photo/`
export const API_REGISTRY_NOTIFICATION = `/api/v1/registry/answer/notification_data`


// URL generator functions
export const getChecklistDeleteDraftURL = (checklistID: string) => `${API_REGISTRY_CHECKLIST}${checklistID}/delete_draft/`;
export const getUpdateChecklistURL = (checklistID: string) => `${API_REGISTRY_CHECKLIST}${checklistID}/`;
export const getAnswerDeleteURL = (answerID: string) => `${API_REGISTRY_ANSWER}${answerID}/delete-answer/`;
export const getPhotoDeleteURL = (photoID: string) => `${API_REGISTRY_PHOTO}${photoID}/delete/`;
export const getDeletePhotoByAnswerURL = (answerID: string) => `${API_REGISTRY_PHOTO}${answerID}/delete_decided/`;
export const getCancelReturnAnswer = (answerID: string) => `${API_REGISTRY_ANSWER}${answerID}/cancel_return_answer/`;
export const getSavedChecklist = (checklistId: string) => `${API_REGISTRY_CHECKLIST}${checklistId}/saved`;
export const getAnswerAssignURL = (answerID: string) => `${API_REGISTRY_ANSWER}${answerID}/assign/`;
export const getAnswerConfirmURL = (answerID: string) => `${API_REGISTRY_ANSWER}${answerID}/confirm/`;
export const getAnswerDecidedURL = (answerID: string) => `${API_REGISTRY_ANSWER}${answerID}/decided/`;
