import {Grid, GridItem} from "@consta/uikit/Grid";
import "../../pages/report/component/css/answerDetail.css"
import React, {useState} from "react";
import {fetchAnswerAssign, fetchAnswerConfirm, fetchDeleteDecidedPhoto} from "../../utils/service/fetch.endpoint";
import {AnswerInterface, viewTypeType} from "../../pages/responsible/page.remark";
import AnswerReport from "../../molecules/report/answerReport";
import AnswerForm from "../../molecules/report/answerForm";
import AnswerHeader from "../../molecules/report/answerHeader";
import RemarkDetail from "../../molecules/report/remarkDetail";
import RejectRemarkCard from "../../molecules/report/rejectRemarkCard";
import {useDispatch, useSelector} from "react-redux";
import {showSnackBar} from "../../utils/features/snackBarSlice";
import {
    selectIsDecideAnswer,
    selectIsReturnedAnswer,
} from "../../utils/features/selectors";
import {setIsAnswerSidebarOpen} from "../../utils/features/sidebarsOpenSlice";
import {clearAnswerState, setIsDecide, setIsReturned} from "../../utils/features/answerSlice";


interface AnswerDetailInterface {
    viewType: viewTypeType
    isConfirmed?: boolean
    selectedAnswerData?: AnswerInterface
    updateIsAnswer: any
}

const AnswerSidebar = (props: AnswerDetailInterface) => {
    const dispatch = useDispatch();

    const isDecide = useSelector(selectIsDecideAnswer)
    const isReturn = useSelector(selectIsReturnedAnswer)

    const [clickConfirm, setClickConfirm] = useState<boolean>(false)

    // взять в работу
    const handleHeaderAssignClick = () => {
        if (!props.selectedAnswerData) {
            return
        }
        dispatch(setIsAnswerSidebarOpen())
        dispatch(showSnackBar({
            messageText: 'Нарушение взято в работу!',
            status: 'success',
        }));
        fetchAnswerAssign({answerID: props.selectedAnswerData.answer_id})
    }


    const handleConfirmClick = () => {
        if (!props.selectedAnswerData) {
            return
        }
        setClickConfirm(true)
        fetchAnswerConfirm({answerID: props.selectedAnswerData.answer_id})
        props.updateIsAnswer(false, true)
        dispatch(clearAnswerState());
        dispatch(setIsAnswerSidebarOpen())
    }

    const handleCancelDecideClick = async () => {
        if (!props.selectedAnswerData) {
            return
        }
        await fetchDeleteDecidedPhoto({answerID: props.selectedAnswerData.answer_id})
        dispatch(setIsDecide(false))
        dispatch(setIsReturned(false))
    }


    return (
        <React.Fragment>
            <AnswerHeader
                viewType={props.viewType}
                handleHeaderAssignClick={handleHeaderAssignClick}
                handleCancelDecideClick={handleCancelDecideClick}
                handleConfirmClick={handleConfirmClick}
            />
            <Grid cols={1}>
                <RemarkDetail selectedAnswerData={props.selectedAnswerData}/>

                {/*при просмотре не принятого нарушения*/}
                {!props.selectedAnswerData?.decided_date && props.selectedAnswerData?.is_returned &&
                    (!isDecide ?
                        <GridItem col={1}>
                            <AnswerReport selectedAnswerData={props.selectedAnswerData}/>
                        </GridItem> :
                        // устранить нарушение
                        <GridItem col={1}>
                            <AnswerForm selectedAnswerData={props.selectedAnswerData}/>
                        </GridItem>)
                }

                {/*при просмотре устраненного нарушения отправленного на проверку*/}
                {props.selectedAnswerData?.decided_date && !isReturn &&
                    <GridItem col={1}>
                        <AnswerReport selectedAnswerData={props.selectedAnswerData}/>
                    </GridItem>
                }

                {/*при нажатии на badge устранить*/}
                {!isReturn && isDecide && props.selectedAnswerData && !props.selectedAnswerData?.is_returned &&
                    <GridItem col={1}>
                        <AnswerForm selectedAnswerData={props.selectedAnswerData}/>
                    </GridItem>
                }

                {/*при нажатии на badge отклонить*/}
                {isReturn &&
                    <GridItem col={1}>
                        <RejectRemarkCard/>
                    </GridItem>
                }
            </Grid>

        </React.Fragment>
    )
}
export default AnswerSidebar