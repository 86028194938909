import {mainInfoDataCheck} from "./mainInfoDataCheck";
import {BYPASS_TEMPLATE_ID} from "../service/config";
import {CheckedNullCountAnswer} from "./checkedNullCountAnswer";


export const getShouldSaveDraft = (props: any, newTemplateData: any, valueAnswer: any, valueDate: Date | null, valueMainInfo: (string | null)[] | undefined): boolean => {
    if (props.viewMode === 'view' || !mainInfoDataCheck(valueDate, valueMainInfo)) {
        return false;
    }

    const nullCount = CheckedNullCountAnswer(valueAnswer);

    if (newTemplateData?.template_id === BYPASS_TEMPLATE_ID) {
        return (valueAnswer.length * 2) !== nullCount;
    }

    return valueAnswer.length !== nullCount;
}
