export const customEngLocale = {
    rowGroupColumnsEmptyMessage: 'Перетащите сюда, чтобы сгруппировать значения',
    pinColumn: 'Закрепить столбец',
    pinLeft: 'Закрепить слева',
    pinRight: 'Закрепить справа',
    noPin: 'Не закреплять',
    autosizeThisColumn: 'Автонастройка ширины',
    autosizeAllColumns: 'Автонастройка ширины всех колонок',
    groupBy: 'Группировать по',
    resetColumns: 'Сбросить настройки',
    ariaSearch: 'Поиск...',
    columnGroup: 'Группировка'
};