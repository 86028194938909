import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import checklistReducer from '../features/checklistSlice';
import snackBarReducer from '../features/snackBarSlice';
import sidebarsReducer from "../features/sidebarsOpenSlice";
import refetchGridReducer from "../features/gridSlice";
import urlReducer from "../features/urlSlice";
import notificationReducer from "../features/notificationSlice";
import answerReducer from "../features/answerSlice";
import toBackendReducer from "../features/toBackendSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        checklist: checklistReducer,
        snackBar: snackBarReducer,
        sidebarsOpen: sidebarsReducer,
        grid: refetchGridReducer,
        url: urlReducer,
        notification: notificationReducer,
        answer: answerReducer,
        toBackend: toBackendReducer,
    },
});

// Типы для доступа к состоянию и диспатча
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
