// set background colour on every row
import moment from "moment";
import React, {useMemo} from "react";

export const rowStyle = {background: '#eaeaea'};

// set background colour on even rows again, this looks bad, should be using CSS classes
export const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
        return {background: 'white'};
    }
};
// const columnDefs = useMemo(() => [
//     {field: "id", headerName: "id", hide: true},
//     {
//         field: "status",
//         headerName: "",
//         filter: true,
//         width: 60, // Фиксированная ширина для колонки 'status'
//         valueGetter: (params: any) => {
//             const answerList = params.data?.answer_list;
//             if (!answerList) return "Устранено"; // Проверка на undefined
//
//             // Вернуть текстовый статус для фильтрации
//             if (answerList.some((answer: any) => answer.is_decided)) {
//                 return "На проверку";
//             }
//             if (!answerList.some((answer: any) => answer.assigned_id !== "None")) {
//                 return "Не распределено";
//             }
//             const allAssigned = answerList.every((answer: any) =>
//                 answer.assigned_id !== "None" &&
//                 answer.is_decided === false &&
//                 answer.is_confirmed === "None" &&
//                 answer.is_returned === false
//             );
//             if (allAssigned) {
//                 return "В работе";
//             }
//             return "Другой статус";
//         },
//         cellRenderer: (params: any) => {
//             const answerList = params.data?.answer_list;
//
//             const iconStyles = {
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 height: '100%' // Занять всю высоту ячейки
//             };
//
//             // Иконка "Устранено"
//             if (!answerList) {
//                 return (
//                     <span title="Устранено" style={iconStyles}>
//                     <IconCheck style={{color: 'green'}}/>
//                 </span>
//                 );
//             }
//
//
//             // Иконка "На проверку"
//             if (answerList.some((answer: any) => answer.is_decided)) {
//                 return (
//                     <span title="На проверку" style={iconStyles}>
//                     <IconRing style={{color: '#0077d0'}}/>
//                 </span>
//                 );
//             }
//
//             // Иконка "Не распределено"
//             if (!answerList.some((answer: any) => answer.assigned_id !== "None")) {
//                 return (
//                     <span title="Не распределено" style={iconStyles}>
//                     <IconWatchFilled style={{color: 'gray'}}/>
//                 </span>
//                 );
//             }
//
//             // Иконка "В работе"
//             const allAssigned = answerList.every((answer: any) =>
//                 answer.assigned_id !== "None" &&
//                 answer.is_decided === false &&
//                 answer.is_confirmed === "None" &&
//                 answer.is_returned === false
//             );
//             if (allAssigned) {
//                 return (
//                     <span title="В работе" style={iconStyles}>
//                     <IconHelmet style={{color: 'orange'}}/>
//                 </span>
//                 );
//             }
//
//
//             // Иконка для неизвестного или другого статуса
//             return (
//                 <span title="Другой статус" style={iconStyles}>
//                 <IconQuestion style={{color: 'gray'}}/>
//             </span>
//             );
//
//         },
//     },
//     {
//         field: "watch_date",
//         headerName: "Дата",
//         filter: true,
//         valueFormatter: (params: any) => moment(params.value).format("DD.MM.YYYY"),
//         width: 120
//     },
//     {field: "deposit", headerName: "Месторождение", hide: false, filter: true, enableRowGroup: true, flex: 1},
//     {field: "watch_place", headerName: "КП", width: 75, filter: true, enableRowGroup: true,},
//     {field: "author", headerName: "Составил", hide: true, filter: true, enableRowGroup: true, flex: 1},
// ], []);
export const columnDefsInspectorBypass = [
    {
        field: 'id',
        headerName: 'id',
        hide: true
    },
    {
        field: 'date',
        headerName: 'Дата',
        width: 100,
        flex:0,
        autoHeight: true,
        filter: true,
        tooltipField: "date",
        sortable: true,
        enableRowGroup: true,
        // Сортировка по исходной дате (сначала новые, потом старые)
        comparator: (valueA: any, valueB: any) => {
            const dateA = moment(valueA, 'DD-MM-YYYY').toDate().getTime();
            const dateB = moment(valueB, 'DD-MM-YYYY').toDate().getTime();
            return dateB - dateA; // Меняем порядок сортировки
        },
        // Форматирование для отображения
        valueFormatter: (params: any) => moment(params.value, 'DD-MM-YYYY').format('DD.MM.YYYY'),
        // Указываем сортировку по умолчанию (от новых к старым)
    },
    {field: "deposit", headerName: "Месторождение", hide: true, filter: true, enableRowGroup: true, flex: 1},
    {
        field: 'watch_place',
        headerName: 'КП',
        flex:0,
        width: 75,
        filter: true,
        enableRowGroup: true,
    },
    {
        field: 'watch_object',
        headerName: 'Обьект',
        width: 100,
        filter: true,
        enableRowGroup: true,
    },
        {
        field: 'author',
        headerName: 'Составил',
        flex:1,
            // width: 100,
        filter: true,
        enableRowGroup: true,
    },
    {
        field: 'is_karkas',
        headerName: 'КБ',
        // width: 100,
        filter: true,
        hide: true,
        enableRowGroup: true,
        // valueFormatter: (params: any) => params.value ? 'ДА' : 'НЕТ'
    },
]

export const columnDefsInspectorKB = [
    {
        field: 'id',
        headerName: 'id',
        hide: true
    },
    {
        field: 'date',
        headerName: 'Дата',
        width: 115,
        autoHeight: true,
        filter: true,
        tooltipField: "date",
        enableRowGroup: true,
        // wrapText: true
    },
    {
        field: 'checklist_name',
        headerName: 'Чеклист',
        // width: 100,
        autoHeight: true,
        filter: true,
        wrapText: true,
        enableRowGroup: true,
    },
    {
        field: 'count',
        headerName: '!',
        // width: 100,
        filter: true,
        enableRowGroup: true,
    },
]

export function gridContext() {
    let result = [];
    result.push(
        {
            name: 'Посмотреть',
            action: async () => {
                console.log('watch')
            },
            icon: '<img src="/icon/view1cm.png" alt="View"/>'
        },
        {
            name: 'Изменить',
            action: async () => {
                console.log('1')
            },
            icon: '<img src="/icon/edit1cm.png" alt="Edit"/>'
        },
        {
            name: 'Отправить',
            action: async () => {
            },
            icon: '<img src="/icon/send1cm.png" alt="Send"/>'
        },
        // 'copy',
        'separator',
        // 'chartRange',
        {
            name: 'Удалить',
            action: async () => {
                console.log('3')
            },
            icon: '<img src="/icon/delete1cm.png" alt="Delete"/>'
        },
    )

    return result;
}

export const statusPanel = {
    statusPanels: [
        {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
    ],
};

export const colDef = {
    // width: 120,
    sortable: true,
    resizable: true,
    flex: 1,
};