import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Card} from "@consta/uikit/Card";
import TextAtom from "../../atoms/new/textAtom.new";
import DatePickerAtom from "../../atoms/new/datePickerAtom.new";
import RenderMainInfo, {QuestionDataSourceInterface} from "../../pages/new/view/render.mainInfo.checklist";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../utils/service/reduxStore";
import {setValueDate} from "../../utils/features/checklistSlice";
import {
    selectSelectedTemplate,
    selectValueMainInfo,
    selectViewMode
} from "../../utils/features/selectors";


const MainInfo: React.FC<QuestionDataSourceInterface> = (props) => {
    const dispatch = useDispatch();
    const viewMode = useSelector(selectViewMode);
    const valueMainInfo = useSelector(selectValueMainInfo);
    // const mainInfoData = useSelector(selectMainMenuElements);
    // const valueDate = useSelector(setValueDate  );
    const currentTemplate = useSelector(selectSelectedTemplate)
    const valueDate = useSelector((state: RootState) =>
        state.checklist.valueDate ? new Date(state.checklist.valueDate) : null
    );

    return (
        <Grid cols={6}>
            <GridItem className="gridSlave" col={6}>
                <Card className='card-view'>
                    <Grid cols={6}>
                        <GridItem col={6}>
                            <TextAtom content={currentTemplate?.name}/>
                        </GridItem>
                        <GridItem col={6}>
                            {viewMode !== "view" ?
                                <DatePickerAtom
                                    placeholder='Дата осмотра'
                                    value={valueDate}
                                    onChange={(date) => dispatch(setValueDate(date ? date.toISOString() : null))}
                                /> :
                                `Дата осмотра: ${moment(valueDate).format('DD.MM.YYYY')}`}
                        </GridItem>
                        {
                            props.mainMenuElements.length > 0 &&
                            valueMainInfo &&
                            <RenderMainInfo questionDataSource={props.questionDataSource}
                                            mainMenuElements={props.mainMenuElements}/>
                        }
                    </Grid>
                </Card>
            </GridItem>
        </Grid>
    )
}


export default MainInfo;
