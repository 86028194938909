import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Интерфейс для состояния пользователя
interface Token {
    exp: number;
    username: string;
    userRole: string;
    last_name: string;
    first_name: string;
    isContract: string;
    user_company: string;
}

// Интерфейс для состояния пользователя в Redux
interface UserState {
    user: Token | null;
}

// Начальное состояние
const initialState: UserState = {
    user: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Экшен для установки пользователя
        setUser: (state, action: PayloadAction<Token>) => {
            state.user = action.payload;
        },
        // Экшен для очистки пользователя
        clearUser: (state) => {
            state.user = null;
        },
    },
});

// Экспортируем экшены и редьюсер
export const {setUser, clearUser} = userSlice.actions;
export default userSlice.reducer;
